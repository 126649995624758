export const WEOLBUS_DESCRIPTION_TEST_TARGETS = [
  3923, 3872, 3833, 3831, 3832, 3873, 3830, 3871, 3986, 3987, 4009, 4010,
];

// RP 고객이 월부스 구매 => 레퍼럴 => 다른상품으로 초대
export const WEOLBUS_RP_REFERRAL_TEST_TARGET: Record<
  number,
  { displaySeq: number; kakaoTitle: string; kakaoThumb: string; kakaoDescription: string; kakaoButtonText: string }
> = {
  3988: {
    displaySeq: 4016,
    kakaoThumb: 'https://cdn.weolbu.com/fe/product/4016-thumb-05.webp?w=800',
    kakaoTitle: '친구가 선물을 보냈어요!',
    kakaoDescription: '애드센스 블로그 라이브를 0원에 구매할 수 있어요',
    kakaoButtonText: '지금 선물받기',
  },
  3989: {
    displaySeq: 4016,
    kakaoThumb: 'https://cdn.weolbu.com/fe/product/4016-thumb-05.webp?w=800',
    kakaoTitle: '친구가 선물을 보냈어요!',
    kakaoDescription: '애드센스 블로그 라이브를 0원에 구매할 수 있어요',
    kakaoButtonText: '지금 선물받기',
  },
  4011: {
    displaySeq: 4017,
    kakaoThumb: 'https://cdn.weolbu.com/fe/product/4017-thumb-05.webp?w=800',
    kakaoTitle: '친구가 선물을 보냈어요!',
    kakaoDescription: '스마트 스토어 라이브를 0원에 구매할 수 있어요',
    kakaoButtonText: '지금 선물받기',
  },
  4012: {
    displaySeq: 4017,
    kakaoThumb: 'https://cdn.weolbu.com/fe/product/4017-thumb-05.webp?w=800',
    kakaoTitle: '친구가 선물을 보냈어요!',
    kakaoDescription: '스마트 스토어 라이브를 0원에 구매할 수 있어요',
    kakaoButtonText: '지금 선물받기',
  },
  3994: {
    displaySeq: 4037,
    kakaoThumb: 'https://cdn.weolbu.com/fe/product/4037-thumb-01.webp?w=800',
    kakaoTitle: '친구가 선물을 보냈어요!',
    kakaoDescription: '재테크 기초 라이브를 0원에 구매할 수 있어요',
    kakaoButtonText: '지금 선물받기',
  },
};

export const getPaymentTypeList = () => {
  const {
    public: { pgIdKcp, pgIdKakaoPay, pgIdNavePay, pgIdTossPay, channelKeyNaverPay },
  } = useRuntimeConfig();
  return [
    {
      name: '신용 • 체크 카드',
      value: 'card',
      pg: pgIdKcp,
      payMethod: 'card',
      icon: 'pi pi-card',
    },
    {
      name: '카카오페이',
      value: 'kakao',
      pg: pgIdKakaoPay,
      payMethod: 'kakaopay',
      icon: 'pi pi-kakao',
    },
    {
      name: '토스페이',
      value: 'toss',
      pg: pgIdTossPay,
      payMethod: 'card',
      icon: 'pi pi-toss',
    },
    {
      name: '네이버페이',
      value: 'naver',
      pg: pgIdNavePay,
      payMethod: 'card',
      icon: 'pi pi-naverpay',
      channelKey: channelKeyNaverPay,
    },
  ];
};
